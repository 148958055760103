import { CitationSourceType } from "../../../shared/enums/CitationSourceType";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { CompanyDocText } from "../models/CompanyDocText";

export const useGetCompanyDocumentSource = () => {
  const {
    data: source,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<CompanyDocText>(
    (response) => new CompanyDocText(response),
  );
  const getCompanyDocumentSource = async (
    documentType: CitationSourceType,
    documentId: string,
    chunkId?: number,
  ) => {
    await call(`/marketData/sources/companyDocs`, "GET", {
      documentType,
      documentId,
      chunkId,
    });
  };

  return { source, getCompanyDocumentSource, isLoading, isFailed };
};
