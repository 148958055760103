import {
  Accordion,
  Button,
  Code,
  Group,
  ScrollArea,
  Text,
} from "@mantine/core";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { PromptVersion } from "../models/PromptVersion";
import { useState } from "react";

interface PromptVersionProps {
  version: PromptVersion;
  index: number;
  promptVersions: PromptVersion[];
  revertToVersion: (version: PromptVersion) => void;
}

export default function PromptVersionView({
  version,
  index,
  promptVersions,
  revertToVersion,
}: PromptVersionProps) {
  const [isDiffWithCurrent, setIsDiffWithCurrent] = useState(false);
  const [isSplitView, setIsSplitView] = useState(true);

  return (
    <Accordion.Item
      value={`${version.createdAt.toString()} | ${version.authorEmail} ${index === 0 ? " (currently active version)" : ""}`}
    >
      <Accordion.Control>
        {`${version.createdAt.toString()} | ${version.authorEmail} ${index === 0 ? " (currently active version)" : ""}`}
      </Accordion.Control>
      <Accordion.Panel>
        <Group pb={10}>
          {index === promptVersions.length - 1 ? (
            <Text>temp: {version.temperature}</Text>
          ) : (
            <Text fz="md">
              temp:{" "}
              {isDiffWithCurrent
                ? promptVersions[0].temperature
                : promptVersions[index + 1].temperature}
              {isDiffWithCurrent
                ? " (currently active version)"
                : " (previous version)"}
              {"  -->  "}
              {version.temperature} (this version)
            </Text>
          )}

          <Text fz="md">|</Text>
          {index === promptVersions.length - 1 ? (
            <Text>maxTokens: {version.maxTokens}</Text>
          ) : (
            <Text fz="md">
              maxTokens:{" "}
              {isDiffWithCurrent
                ? promptVersions[0].maxTokens
                : promptVersions[index + 1].maxTokens}
              {isDiffWithCurrent
                ? " (currently active version)"
                : " (previous version)"}
              {"  -->  "}
              {version.maxTokens} (this version)
            </Text>
          )}
        </Group>

        {index != promptVersions.length - 1 ? (
          <>
            <Text fw={700}>
              {isDiffWithCurrent
                ? "Showing the difference with the currently active version. Current version is on the left, this version is on the right."
                : "Showing the difference with the previous version that is below this one. Previous version is on the left, this version is on the right."}
            </Text>
            <ScrollArea.Autosize mah={700} pb={10}>
              <ReactDiffViewer
                oldValue={
                  isDiffWithCurrent
                    ? promptVersions[0].text
                    : promptVersions[index + 1].text
                }
                newValue={version.text}
                leftTitle={
                  isDiffWithCurrent
                    ? "Currently active version"
                    : "Previous version"
                }
                rightTitle={"This version"}
                splitView={isSplitView}
                compareMethod={DiffMethod.WORDS}
                styles={{
                  contentText: {
                    fontSize: "11px",
                  },
                  line: {
                    wordBreak: "break-word",
                  },
                  lineNumber: {
                    fontSize: "11px",
                  },
                  diffContainer: {
                    "& pre": {
                      lineHeight: "16px !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    },
                  },
                }}
              />
            </ScrollArea.Autosize>
          </>
        ) : (
          <>
            <Text fw={700}>Prompt Text:</Text>
            <Code block mb={10} mah={700}>
              {version.text}
            </Code>
          </>
        )}

        <Group gap={10}>
          <Button
            onClick={() => {
              revertToVersion(version);
            }}
            radius="xs"
            disabled={index === 0}
          >
            Set this as current version
          </Button>
          {index != promptVersions.length - 1 && (
            <>
              <Button
                onClick={() => {
                  setIsDiffWithCurrent(!isDiffWithCurrent);
                }}
                radius="xs"
                variant="secondary"
                disabled={index === 0}
              >
                {isDiffWithCurrent
                  ? "Show diff with the version below this one (with previous version)"
                  : "Show diff with currently active version"}
              </Button>
              <Button
                onClick={() => {
                  setIsSplitView(!isSplitView);
                }}
                radius="xs"
                variant="secondary"
              >
                {isSplitView ? "Show unified view" : "Show split view"}
              </Button>
            </>
          )}
          {index === 0 && <Text>This is the current version</Text>}
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
