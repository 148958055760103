import {
  rem,
  Image,
  Paper,
  Group,
  Box,
  Flex,
  Text,
  Stack,
} from "@mantine/core";
import logo from "../../../../public/images/logoMonoBlack.svg";
import classes from "../styles/MessageShell.module.css";
import { ChatMessage } from "../models/ChatMessage";
import FailedMessage from "./FailedMessage";
import { MessageProcessor } from "../models/MessageDataProcessor";
import Timer from "../../../shared/components/timer/Timer";
import { useAdminView } from "../../../shared/hooks/useAdminView";
import { useHasAdminRole } from "../../../shared/hooks/useHasRole";

interface MessageShellProps {
  message: ChatMessage;
  chatId: string;
  isLast: boolean;
}

export default function MessageShell({
  message,
  chatId,
  isLast,
}: MessageShellProps) {
  const isAdmin = useHasAdminRole();
  const [isAdminView] = useAdminView();
  return (
    <Stack gap={rem(8)}>
      <Flex justify="flex-start" align="flex-start" direction="row">
        <Paper className={classes["paper"]}>
          <Group align="flex-start" gap={rem(19)}>
            <Image className={classes["logo"]} src={logo} h={20} />
            <Box className={classes["content-container"]}>
              <Group justify="space-between">
                {isAdmin && isAdminView && (
                  <>
                    <Text>{message.data?.type}</Text>
                    <Timer
                      startedAt={message.startedAt}
                      finishedAt={message.finishedAt}
                      variant="neutral"
                    />
                  </>
                )}
              </Group>
              {message.data &&
                MessageProcessor.GetComponent(message.data, message.inProgress)}
              {message.error && (
                <FailedMessage
                  chatId={chatId}
                  isLast={isLast}
                  message={message}
                />
              )}
            </Box>
          </Group>
        </Paper>
      </Flex>
    </Stack>
  );
}
