import { useLocation, useParams } from "react-router-dom";
import FilingBody from "../features/filings/FilingBody";
import CustomAppShell from "../shared/components/app-shell/CustomAppShell";
import { getChunkIdFromQuery } from "../shared/utils/Strings";

export default function Filing() {
  const { filingId } = useParams();

  const location = useLocation();
  const chunkId = getChunkIdFromQuery(location.search);

  return (
    <CustomAppShell>
      {filingId && <FilingBody id={filingId} chunkId={chunkId} />}
    </CustomAppShell>
  );
}
