import { Group, Stack, Text } from "@mantine/core";
import classes from "../../styles/SidebarCitations.module.css";
import { IconWorldSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";

interface SidebarNewsLinkProps {
  title: string;
  url: string;
  source: string;
}

export default function SidebarNewsLink({
  title,
  url,
  source,
}: SidebarNewsLinkProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Stack className={classes["link-group"]}>
        <Group>
          <IconWorldSearch
            stroke={1.5}
            color="var(--mantine-color-primary-5)"
            size={isLargeScreen ? 20 : 16}
          />

          <Text className={classes["link-text"]}>{source}</Text>
        </Group>
        <Group>
          <Text className={`${classes["link-text"]} ${classes["news-title"]}`}>
            {title}
          </Text>
        </Group>
      </Stack>
    </a>
  );
}
