import { useGetUserContentChunk } from "../../../sources/api/useGetUserContentChunk";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { Group, Stack, ScrollArea, Text, Box, rem } from "@mantine/core";
import { parseIntOrUndefined } from "../../../../shared/utils/Number";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarUserContent from "./SidebarUserContent";
import classes from "../../styles/SidebarCitations.module.css";

interface SidebarUserContentCitationProps {
  citation: CitationIndex;
  chatId: string;
}

export function SidebarUserContentCitation({
  citation,
  chatId,
}: SidebarUserContentCitationProps) {
  const { source, getUserContentChunkText, isLoading } =
    useGetUserContentChunk();

  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getUserContentChunkText(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      chatId,
      parseIntOrUndefined(memoizedCitation.chunkId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      <Group>
        {source && (
          <SidebarUserContent
            chatId={chatId}
            contentId={source.id}
            chunkText={source.chunkText}
          />
        )}
      </Group>
      <Stack h="100%">
        {source && (
          <>
            <ScrollArea.Autosize
              scrollbars="y"
              offsetScrollbars
              scrollbarSize={4}
            >
              <Text className={classes["chunk-text"]}>{source.chunkText}</Text>
            </ScrollArea.Autosize>
          </>
        )}
      </Stack>
      <Box py={rem(4)}></Box>
    </>
  );
}
