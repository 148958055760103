import { dateTimeToString } from "../../../shared/utils/Dates";
import { Filing } from "../models/Filing";
import ScrollableTextWithHighlighting from "../../../shared/components/scrollable-text-highlighting/ScrollableTextWithHighlighting";
import { Text } from "@mantine/core";

interface FilingContentProps {
  filing: Filing;
}

export default function FilingContent({ filing }: FilingContentProps) {
  if (filing.link) {
    window.location.href = filing.link;
    return <></>;
  }

  return (
    <>
      <h2>{filing.title}</h2>
      <p>{dateTimeToString(filing.filingDate)}</p>
      {filing.fullText ? (
        <ScrollableTextWithHighlighting
          fullText={filing.fullText}
          textToHighlight={filing.selectedChunkText}
        />
      ) : (
        <Text>No text available</Text>
      )}
    </>
  );
}
