import { useEffect } from "react";
import { Box, Center, Loader, rem } from "@mantine/core";
import { useGetFiling } from "./api/useGetFiling";
import FilingContent from "./components/FilingContent";
import { useResetHash } from "../../shared/hooks/useResetHash";

interface FilingBodyProps {
  id: string;
  chunkId?: number;
}

export default function FilingBody({ id, chunkId }: FilingBodyProps) {
  const { filing, getFiling, isLoading } = useGetFiling();

  useEffect(() => {
    getFiling(id, chunkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, chunkId]);

  useResetHash(filing);

  return (
    <Box h="100%" p={`${rem(34)} ${rem(34)} 0 ${rem(34)}`}>
      {isLoading && (
        <Center h="100%" w="100%">
          <Loader />
        </Center>
      )}
      {filing && <FilingContent filing={filing} />}
    </Box>
  );
}
