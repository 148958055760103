export class ConsensusEstimate {
  companyName: string;
  periodType: string;
  fiscalQuarter: number;
  fiscalYear: number;
  estimateType: string;
  currency?: string;
  scale?: string;
  value: number;
  note?: string;

  constructor(data: any) {
    this.companyName = data.companyName;
    this.periodType = data.periodType;
    this.fiscalQuarter = data.fiscalQuarter;
    this.fiscalYear = data.fiscalYear;
    this.estimateType = data.estimateType;
    this.currency = data.currency;
    this.scale = data.scale;
    this.value = data.value;
    this.note = data.note;
  }
}
