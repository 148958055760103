import { CitationSourceType } from "../../../shared/enums/CitationSourceType";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { UserContentChunkText } from "../models/UserContentChunkText";

export const useGetUserContentChunk = () => {
  const {
    data: source,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<UserContentChunkText>(
    (response) => new UserContentChunkText(response),
  );
  const getUserContentChunkText = async (
    documentType: CitationSourceType,
    documentId: string,
    chatId: string,
    chunkId?: number,
  ) => {
    await call(`chat/${chatId}/userContentChunk`, "GET", {
      documentType,
      documentId,
      chunkId,
    });
  };

  return { source, getUserContentChunkText, isLoading, isFailed };
};
