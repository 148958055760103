import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";
import { Box, Group, Modal, ScrollArea, Stack, Text } from "@mantine/core";
import { IconFileTypePdf } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useGetChatUserContent } from "../../../sources/api/useGetChatUserContent";
import ScrollableTextWithHighlighting from "../../../../shared/components/scrollable-text-highlighting/ScrollableTextWithHighlighting";
import classes from "../../styles/SidebarCitations.module.css";

interface SidebarUserContentProps {
  chatId: string;
  contentId: string;
  chunkText?: string;
}

export default function SidebarUserContent({
  chatId,
  contentId,
  chunkText,
}: SidebarUserContentProps) {
  const [opened, { open, close }] = useDisclosure(false);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  const { userContents, getUserContents } = useGetChatUserContent();

  useEffect(() => {
    getUserContents(chatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const userContent = useMemo(() => {
    if (userContents)
      return userContents.find((content) => content.id === contentId);
  }, [contentId, userContents]);

  return userContent ? (
    <>
      <Box onClick={open}>
        <Stack className={classes["link-group"]}>
          <Group>
            <IconFileTypePdf
              color="var(--mantine-color-primary-5)"
              size={isLargeScreen ? 20 : 18}
            />
            <Text className={classes["link-text"]}>{userContent.title}</Text>
          </Group>
        </Stack>
      </Box>
      <Modal
        opened={opened}
        onClose={close}
        title={userContent.title}
        radius="xs"
        scrollAreaComponent={ScrollArea.Autosize}
        classNames={{
          content: classes["user-content-modal-content"],
          title: classes["user-content-modal-title"],
          body: classes["user-content-modal-body"],
        }}
      >
        <ScrollableTextWithHighlighting
          fullText={userContent.content}
          textToHighlight={chunkText}
        />
      </Modal>
    </>
  ) : (
    <Text className={classes["source-text"]}> Source document not found. </Text>
  );
}
