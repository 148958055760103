export class UserContentChunkText {
  id: string;
  title: string;
  chunkId?: number;
  chunkText?: string;

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.chunkId = data.chunkId;
    this.chunkText = data.chunkText;
  }
}
