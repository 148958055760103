import {
  Code,
  Collapse,
  Button,
  ScrollArea,
  Stack,
  Group,
} from "@mantine/core";
import { useHasAdminRole } from "../../../shared/hooks/useHasRole";
import { useAdminView } from "../../../shared/hooks/useAdminView";
import { useDisclosure } from "@mantine/hooks";
import JsonFormatter from "react-json-formatter";

interface FunctionCallProps {
  functionCalls?: any[];
}

export default function FunctionCall({ functionCalls }: FunctionCallProps) {
  const isAdmin = useHasAdminRole();
  const [isAdminView] = useAdminView();
  const [opened, { toggle }] = useDisclosure(false);

  try {
    functionCalls = functionCalls?.map((fc: any) => {
      if (fc.arguments) {
        fc.arguments = JSON.parse(fc.arguments);
      }
      return fc;
    });
  } catch {
    /* empty */
  }

  return (
    <>
      {isAdmin && isAdminView && functionCalls && (
        <Stack>
          <Button variant="tertiary" size="xs" w={150} onClick={toggle}>
            {opened
              ? `Hide function call${functionCalls.length > 1 ? "s" : ""}`
              : `Show ${functionCalls ? functionCalls.length + " " : ""}function call${functionCalls.length > 1 ? "s" : ""}`}
          </Button>
          <Group align="flex-start">
            {functionCalls.map((fc, index) => (
              <Collapse key={index} in={opened}>
                <ScrollArea.Autosize mah={400}>
                  <Code block color={"transparent"}>
                    <JsonFormatter json={fc} />
                  </Code>
                </ScrollArea.Autosize>
              </Collapse>
            ))}
          </Group>
        </Stack>
      )}
    </>
  );
}
