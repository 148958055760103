import { ActionIcon, ScrollArea, Stack } from "@mantine/core";
import { useRef, useState, useEffect } from "react";
import { useAdminView } from "../../../shared/hooks/useAdminView";
import { useHasAdminRole } from "../../../shared/hooks/useHasRole";
import { ChatShort } from "../../../shared/models/Chat";
import ChatInfo from "./ChatInfo";
import { ChatMessage } from "../models/ChatMessage";
import classes from "../styles/ExistingChat.module.css";
import { UserInputMessageData } from "../models/message-data/UserInputMessageData";
import UserInputMessage from "./messages/UserInputMessage";
import { MessageDataType } from "../enums/MessageDataType";
import MessageShell from "./MessageShell";
import { IconArrowDown } from "@tabler/icons-react";
import { ModelOutputMessageData } from "../models/message-data/ModelOutputMessageData";
import { ExecutionPlan } from "./ExecutionPlan";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface MessagesListProps {
  chat: ChatShort;
  messages: ChatMessage[];
}

export default function MessageList({ messages, chat }: MessagesListProps) {
  const isAdmin = useHasAdminRole();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isAdminView] = useAdminView();

  useEffect(() => {
    if (!isAtBottom || !scrollRef.current) return;

    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: "instant",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const scrollToBottom = () => {
    if (!scrollRef.current) return;

    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: "instant",
    });
    setIsAtBottom(true);
  };

  useEffect(() => {
    const handleWheel = (event: any) => {
      if (!scrollRef.current) return;

      const isUserAtBottom =
        scrollRef.current.scrollHeight -
          scrollRef.current.scrollTop -
          event.deltaY <=
        scrollRef.current.clientHeight;

      setIsAtBottom(isUserAtBottom);
    };

    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener("wheel", handleWheel);

    // Scroll to bottom on component mount
    setTimeout(scrollToBottom, 5);
    return () => scrollElement?.removeEventListener("wheel", handleWheel);
  }, []);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <ScrollArea
      h="100%"
      classNames={{
        thumb: classes["scroll-area-thumb"],
      }}
      viewportRef={scrollRef}
    >
      <Stack className={classes["chat-stack"]}>
        {isAdmin && isAdminView && <ChatInfo chat={chat} />}
        <Stack gap={10}>
          {messages.map((message, i) => {
            if (message.error)
              return (
                <MessageShell
                  key={message.id}
                  message={message}
                  chatId={chat.id}
                  isLast={i === messages.length - 1}
                />
              );

            const data = message.data;
            if (!data) {
              return <CenterLoader key={message.id} />;
            }

            const type = data.type;
            const functionCalls = (data as ModelOutputMessageData)
              .functionCalls;
            if (type === MessageDataType.UserInputMessageData) {
              return (
                <UserInputMessage
                  key={message.id}
                  data={data as UserInputMessageData}
                  chatId={chat.id}
                />
              );
            }

            if (
              (type === MessageDataType.ModelOutputMessageData &&
                !functionCalls) ||
              type === MessageDataType.GetMarketDataMessageData ||
              type === MessageDataType.ExecuteCodeMessageData
            ) {
              return (
                <MessageShell
                  key={message.id}
                  message={message}
                  chatId={chat.id}
                  isLast={i === messages.length - 1}
                />
              );
            }

            // Find only function calls after user message
            const isPreviousUserMessage =
              messages[i - 1]?.data?.type ===
              MessageDataType.UserInputMessageData;
            if (!isPreviousUserMessage) return null;

            const executionPlanMessages = [] as ChatMessage[];
            // Find all function calls before next model output without function call
            const nextModelOutputMessageIndex = messages
              .slice(i)
              .findIndex((m) => {
                return (
                  m.data?.type === MessageDataType.ModelOutputMessageData &&
                  !(m.data as ModelOutputMessageData).functionCalls
                );
              });
            if (nextModelOutputMessageIndex === -1) {
              executionPlanMessages.push(...messages.slice(i));
            } else {
              executionPlanMessages.push(
                ...messages.slice(i, i + nextModelOutputMessageIndex),
              );
            }

            if (executionPlanMessages.length !== 0)
              return (
                <ExecutionPlan
                  key={message.id}
                  messages={executionPlanMessages}
                />
              );
          })}
        </Stack>
      </Stack>
      {!isAtBottom && (
        <ActionIcon
          variant="light"
          onClick={scrollToBottom}
          size={isLargeScreen ? "md" : "sm"}
          style={{
            zIndex: 10,
            position: "absolute",
            right: "50%",
            bottom: "1%",
          }}
        >
          <IconArrowDown />
        </ActionIcon>
      )}
    </ScrollArea>
  );
}
