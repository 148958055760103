import { TranscriptComponent } from "./TranscriptComponent";

export class Transcript {
  public id: number;
  public title: string;
  public date: Date;
  public selectedChunkText: string | null;
  public components: TranscriptComponent[];

  constructor(transcript: any) {
    this.id = transcript.id;
    this.title = transcript.title;
    this.date = new Date(transcript.date);
    this.selectedChunkText = transcript.selectedChunkText;
    this.components = transcript.components.map(
      (component: any) => new TranscriptComponent(component),
    );
  }
}
