import { useLocation, useParams } from "react-router-dom";
import TranscriptBody from "../features/transcripts/TranscriptBody";
import CustomAppShell from "../shared/components/app-shell/CustomAppShell";
import { getChunkIdFromQuery } from "../shared/utils/Strings";

export default function Transcript() {
  const { transcriptId } = useParams();

  const location = useLocation();
  const chunkId = getChunkIdFromQuery(location.search);

  return (
    <CustomAppShell>
      {transcriptId && (
        <TranscriptBody id={parseInt(transcriptId)} chunkId={chunkId} />
      )}
    </CustomAppShell>
  );
}
