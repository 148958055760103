import { useGetCompanyDocumentSource } from "../../../sources/api/useGetCompanyDocumentSource";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { Group, ScrollArea, Text, Box, rem } from "@mantine/core";
import { parseIntOrUndefined } from "../../../../shared/utils/Number";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarCompanyDocumentLink from "./SidebarCompanyDocumentLink";
import classes from "../../styles/SidebarCitations.module.css";

interface SidebarDocumentCitationProps {
  citation: CitationIndex;
}

export function SidebarDocumentCitation({
  citation,
}: SidebarDocumentCitationProps) {
  const { source, getCompanyDocumentSource, isLoading } =
    useGetCompanyDocumentSource();
  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getCompanyDocumentSource(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      parseIntOrUndefined(memoizedCitation.chunkId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      <Group>
        {source && (
          <SidebarCompanyDocumentLink
            document={source}
            chunkId={parseIntOrUndefined(memoizedCitation.chunkId)}
          />
        )}
      </Group>
      {source && (
        <>
          <ScrollArea.Autosize
            scrollbars="y"
            offsetScrollbars
            scrollbarSize={4}
          >
            <Text className={classes["chunk-text"]}>{source.content}</Text>
          </ScrollArea.Autosize>
        </>
      )}
      <Box py={rem(4)}></Box>
    </>
  );
}
