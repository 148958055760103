import Markdown, { ExtraProps } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import CitationSidebarCard from "./CitationSidebarCard";
import { useEffect, useState } from "react";
import { Citation } from "./models/Citation";
import { ContentWithCitations } from "./models/ContentWithCitations";
import { Button } from "@mantine/core";
import { downloadFile } from "../../shared/utils/Files";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../shared/utils/ResponsiveStyles";

interface MarkdownWithCitationsProps {
  content: string;
  className?: string;
}

export function MarkdownWithCitations({
  content,
  className,
}: MarkdownWithCitationsProps) {
  const [citations, setCitations] = useState<Citation[]>([]);
  const [markdownContent, setMarkdownContent] = useState<string>("");

  useEffect(() => {
    // Extract citations from the content
    const contentWithCitations = new ContentWithCitations(content);

    setCitations(contentWithCitations.citations);

    // Process content to handle math equations and currency symbols
    const processedText = contentWithCitations.formattedContent
      // Escape dollar signs for currency
      .replace(/\$/g, "\\$")
      // Replace escaped brackets with dollar signs for block math
      .replace(/\\\[/g, "$$")
      .replace(/\\\]/g, "$$");

    setMarkdownContent(processedText);
  }, [content]);

  const formatCitation = (
    ops: React.ClassAttributes<HTMLElement> &
      React.HTMLAttributes<HTMLElement> &
      ExtraProps,
  ) => {
    const defaultReturn = <code>{ops.children}</code>;
    if (typeof ops.children !== "string") return defaultReturn;
    const citationIndex = parseInt(ops.children as string);
    if (isNaN(citationIndex)) return defaultReturn;
    const citation = citations[citationIndex];
    if (!citation) return defaultReturn;

    return <CitationSidebarCard index={citationIndex} citation={citation} />;
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  const formatTable = (
    ops: React.ClassAttributes<HTMLTableElement> &
      React.TableHTMLAttributes<HTMLTableElement> &
      ExtraProps,
  ) => {
    const tableContent = ops.children;

    const downloadCSV = () => {
      if (!tableContent) return;

      let csvContent = "";

      const extractText = (node: any): string => {
        if (typeof node === "string") return node.trim();
        if (Array.isArray(node)) return node.map(extractText).join(" ").trim();
        if (node && typeof node === "object") {
          if (node.props && node.props.children) {
            return extractText(node.props.children);
          }
          if (node.type === "text") return node.value.trim();
        }
        return "";
      };

      const processCell = (cell: string): string => {
        let processed = cell;
        processed = processed.replace(/\*\*/g, ""); // Remove bold Markdown
        processed = processed.replace(/<br\s*\/?>/g, " "); // Replace <br> with space
        processed = processed.replace(/\[\d+]/g, ""); // Remove citation numbers
        processed = processed.replace(/"/g, '""'); // Escape quotes
        return `"${processed}"`;
      };

      const processRow = (row: any): string => {
        if (!row || !row.props || !row.props.children) return "";

        const cells = Array.isArray(row.props.children)
          ? row.props.children
          : [row.props.children];
        return cells
          .map((cell: any) => processCell(extractText(cell)))
          .join(",");
      };

      // In case there is no separate header and body turn tableContent into array
      const parts = Array.isArray(tableContent) ? tableContent : [tableContent];

      parts.forEach((part) => {
        if (part && part.type === "tr") {
          csvContent += processRow(part) + "\n";
        } else if (part && part.props && part.props.children) {
          const rows = Array.isArray(part.props.children)
            ? part.props.children
            : [part.props.children];
          rows.forEach((row: any) => {
            if (row && row.type === "tr") {
              csvContent += processRow(row) + "\n";
            }
          });
        }
      });

      downloadFile(csvContent, `table-${Date.now()}.csv`, "text/csv");
    };

    return (
      <div>
        <table {...ops}>{tableContent}</table>
        <Button
          variant="secondary"
          size={isLargeScreen ? "sm" : "xxs"}
          mt={5}
          px={5}
          h={isLargeScreen ? 35 : 25}
          radius="xxs"
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </div>
    );
  };

  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, rehypeKatex]}
      className={className}
      children={markdownContent}
      components={{
        code: formatCitation,
        table: formatTable,
      }}
    />
  );
}
