import { useApiPrivateRequest } from "../hooks/useApi";
import { ConsensusEstimate } from "../models/ConsensusEstimates";

export const useGetConsensusEstimates = () => {
  const {
    data: estimates,
    call,
    isLoading,
    setData: setCompany,
  } = useApiPrivateRequest<ConsensusEstimate[]>((response) =>
    response.map((result: any) => new ConsensusEstimate(result)),
  );

  const getEstimates = async (
    companyId: number,
    startYear: number,
    endYear: number,
    periodType: string,
  ) => {
    await call(`/marketdata/companies/${companyId}/consensusEstimates`, "GET", {
      startYear,
      endYear,
      periodType,
    });
  };

  return { estimates, getEstimates, isLoading, setCompany };
};
