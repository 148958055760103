import React from "react";
import { CompanyDocumentType } from "../enums/CompanyDocumentType";

export function validateEmail(email: string): boolean {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function formatText(text?: string): JSX.Element[] {
  if (!text) return [];
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
}

export function generateDocumentLink(
  id: string,
  type: CompanyDocumentType,
  chunkId?: number,
): string {
  let link = "";
  switch (type) {
    case CompanyDocumentType.Transcript:
      link = `/transcript/${id}`;
      break;
    case CompanyDocumentType.Filing:
      link = `/filing/${id}`;
      break;
    default:
      link = "";
  }

  if (chunkId) {
    link += `?chunkId=${chunkId}`;
  }

  return link;
}

export function getChunkIdFromQuery(location: string): number | undefined {
  const searchParams = new URLSearchParams(location);
  const chunkId = searchParams.get("chunkId");
  return chunkId ? parseInt(chunkId) : undefined;
}
