import classes from "../styles/Settings.module.css";
import { rem, Modal, Stack, Button, Group } from "@mantine/core";
import SettingsItem from "./SettingsItem";
import { useGetSettings } from "../api/useGetSettings";
import { useEffect } from "react";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import { useAuth0 } from "@auth0/auth0-react";

interface SettingsProps {
  opened: boolean;
  close: () => void;
}

const CopyAccessToken = async (clipboard: any, getAccessTokenSilently: any) => {
  const token = await getAccessTokenSilently();
  clipboard.copy("Bearer " + token);
};

export default function Settings({ opened, close }: SettingsProps) {
  const { settings, getSettings, setSettings } = useGetSettings();
  const { getAccessTokenSilently } = useAuth0();
  const clipboard = useClipboard({ timeout: 2000 });

  useEffect(() => {
    if (opened) getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const handleSettingChange = (key: string, value: string) => {
    setSettings((prevSettings) => {
      if (!prevSettings) return prevSettings;
      const newSettings = [...prevSettings];
      const settingIndex = newSettings.findIndex(
        (setting) => setting.key === key,
      );
      newSettings[settingIndex].value = value;
      return newSettings;
    });
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Modal
      classNames={{
        content: classes["modal-content"],
        header: classes["modal-header"],
        body: classes["modal-body"],
        title: classes["modal-title"],
      }}
      opened={opened}
      onClose={close}
      title="Settings"
      centered
    >
      <Stack gap={isLargeScreen ? rem(12) : rem(6)}>
        {settings &&
          settings.map((setting) => (
            <SettingsItem
              setting={setting}
              key={setting.label}
              onChange={handleSettingChange}
            />
          ))}
      </Stack>
      <Group>
        {clipboard.copied && (
          <Button
            mt={rem(6)}
            variant="outline"
            color="blue"
            onClick={async () =>
              await CopyAccessToken(clipboard, getAccessTokenSilently)
            }
          >
            Access token copied!
          </Button>
        )}
        {!clipboard.copied && (
          <Button
            mt={rem(6)}
            variant="outline"
            color="blue"
            onClick={async () =>
              await CopyAccessToken(clipboard, getAccessTokenSilently)
            }
          >
            Copy JWT access token
          </Button>
        )}
      </Group>
    </Modal>
  );
}
