import { CitationSourceType } from "../../../shared/enums/CitationSourceType";

export class Citation {
  public sourceType: CitationSourceType;
  public documentId: string;
  public chunkId?: string;

  public constructor(
    sourceType: CitationSourceType,
    documentId: string,
    chunkId?: string,
  ) {
    this.sourceType = sourceType;
    this.documentId = documentId;
    this.chunkId = chunkId;
  }
}

export class CitationIndex extends Citation {
  public index: number;

  public constructor(index: number, citation: Citation) {
    super(citation.sourceType, citation.documentId, citation.chunkId);
    this.index = index;
  }
}
