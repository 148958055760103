import { rem, ScrollArea, Table } from "@mantine/core";
import { ConsensusEstimate } from "../../../shared/models/ConsensusEstimates";
import classes from "../styles/CitationHoverCard.module.css";

interface EstimatesTableProps {
  estimates: ConsensusEstimate[];
  isOnHoverCard?: boolean;
}

export function EstimatesTable({
  estimates,
  isOnHoverCard = true,
}: EstimatesTableProps) {
  const rows = estimates.map((e, i) => (
    <Table.Tr key={i}>
      <Table.Td>{e.estimateType}</Table.Td>
      <Table.Td>{e.value}</Table.Td>
      <Table.Td>{e.currency ? e.currency : "-"}</Table.Td>
      <Table.Td>{e.scale ? e.scale : "-"}</Table.Td>
      <Table.Td>{e.note ? e.note : "-"}</Table.Td>
    </Table.Tr>
  ));

  return (
    <ScrollArea.Autosize
      mah={isOnHoverCard ? rem(300) : "100%"}
      offsetScrollbars
      scrollbarSize={4}
    >
      <Table className={classes["text"]}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Metric</Table.Th>
            <Table.Th>Value</Table.Th>
            <Table.Th>Currency</Table.Th>
            <Table.Th>Scale</Table.Th>
            <Table.Th>Note</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea.Autosize>
  );
}
