import FunctionCall from "../FunctionCall";
import { FunctionCallMessageData } from "../../models/message-data/FunctionCallMessageData";

interface FunctionCallMessageProps {
  data: FunctionCallMessageData;
}

export default function FunctionCallMessage({
  data,
}: FunctionCallMessageProps) {
  return <FunctionCall functionCalls={[data]} />;
}
