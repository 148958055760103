export class ChatReport {
  public text: string;
  public createdAt: Date;
  public finishedAt?: Date;

  constructor(response: any) {
    this.text = response.text as string;
    this.createdAt = new Date(response.createdAt);
    if (response.finishedAt) {
      this.finishedAt = new Date(response.finishedAt);
    }
  }
}
