import { useEffect } from "react";
import { Box, Center, Loader, rem } from "@mantine/core";
import { useGetTranscript } from "./api/useGetTranscript";
import TranscriptContent from "./components/TranscriptContent";
import { useResetHash } from "../../shared/hooks/useResetHash";

interface TranscriptBodyProps {
  id: number;
  chunkId?: number;
}

export default function TranscriptBody({ id, chunkId }: TranscriptBodyProps) {
  const { transcript, getTranscript, isLoading } = useGetTranscript();

  useEffect(() => {
    getTranscript(id, chunkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, chunkId]);

  useResetHash(transcript);

  return (
    <Box h="100%" p={`${rem(34)} ${rem(34)} 0 ${rem(34)}`}>
      {isLoading && (
        <Center h="100%" w="100%">
          <Loader />
        </Center>
      )}
      {transcript && <TranscriptContent transcript={transcript} />}
    </Box>
  );
}
