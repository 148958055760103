import markdownClasses from "../../../../shared/styles/Markdown.module.css";
import { ModelOutputMessageData } from "../../models/message-data/ModelOutputMessageData";
import FunctionCall from "../FunctionCall";
import { MarkdownWithCitations } from "../../../sources/MarkdownWithCitations";
import CopyModelOutputIcon from "../CopyModelOutputIcon";
import ContentDocuments from "../../../sources/ContentDocuments";
import { Group } from "@mantine/core";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";

interface ModelOutputMessageProps {
  data: ModelOutputMessageData;
  isInProgress?: boolean;
}

export default function ModelOutputMessage({
  data,
  isInProgress,
}: ModelOutputMessageProps) {
  return (
    <>
      <MarkdownWithCitations
        content={data.content}
        className={markdownClasses["markdown"]}
      />
      {isInProgress && <CenterLoader />}
      <Group align="flex-start" gap={5}>
        {data.functionCalls && (
          <FunctionCall functionCalls={data.functionCalls} />
        )}
      </Group>
      <ContentDocuments content={data.content} />
      {data.content && <CopyModelOutputIcon content={data.content} />}
    </>
  );
}
