import { Accordion, rem, Stepper } from "@mantine/core";
import { ChatMessage } from "../models/ChatMessage";
import { MessageDataType } from "../enums/MessageDataType";
import { ModelOutputMessageData } from "../models/message-data/ModelOutputMessageData";
import classes from "../styles/ExecutionPlan.module.css";
import ModelOutputMessage from "./messages/ModelOutputMessage";
import { FunctionCallMessageData } from "../models/message-data/FunctionCallMessageData";
import { MessageProcessor } from "../models/MessageDataProcessor";
import { ExecutionPlanFailedIcon } from "./ExecutionPlanFailedIcon";

interface ExecutionPlanProps {
  messages: ChatMessage[];
}

const functionCallTitles = new Map<string, string>([
  ["ask_company_documents", "Reading through company documents"], // Old function name
  ["ask_agent", "Reading through company documents"],
  ["ask_user_documents", "Reading through uploaded documents"],
  ["filter_documents", "Filtering documents"],
  ["search_companies", "Searching for companies"],
  ["get_market_data", "Getting market data"],
  ["execute_code", "Executing code"],
  ["calculate", "Crunching numbers"],
  ["get_news", "Getting news"],
  ["get_estimates", "Getting estimates"],
]);

export function ExecutionPlan({ messages }: ExecutionPlanProps) {
  const isPlanFinished = messages[messages.length - 1].finishedAt !== undefined;
  const finishedTitle = "Research completed";
  const workingTitle = "Researching...";
  const accordionTitle = isPlanFinished ? finishedTitle : workingTitle;

  return (
    <Accordion
      variant="contained"
      radius="xs"
      defaultValue="item"
      ml={rem(50)}
      chevronSize={18}
    >
      <Accordion.Item value="item">
        <Accordion.Control
          classNames={{
            control: classes["control"],
            label: classes["label"],
          }}
        >
          {accordionTitle}
        </Accordion.Control>
        <Accordion.Panel
          classNames={{
            panel: classes["accordion-panel"],
            content: classes["accordion-content"],
          }}
        >
          <Stepper
            active={messages.length}
            iconSize={14}
            orientation="vertical"
            styles={{
              stepCompletedIcon: {
                position: "absolute",
                top: -2,
                left: -2,
                height: 14,
                width: 14,
              },
            }}
            classNames={{
              step: classes["step"],
              stepLabel: classes["step-label"],
            }}
          >
            {messages.map((message) => {
              if (message.data?.type !== MessageDataType.ModelOutputMessageData)
                return null;

              const steps = [];

              const data = message.data as ModelOutputMessageData;
              // Function calls are always present in this message as other ones are filtered out in MessageList.tsx: 93
              const functionCalls = data.functionCalls!;
              const isLoading = message.finishedAt === undefined;
              steps.push(
                <Stepper.Step
                  key={message.id}
                  label={"Model output"}
                  description={<ModelOutputMessage data={data} />}
                  loading={isLoading}
                />,
              );
              functionCalls.map((call) => {
                const functionCallMessage = messages.find(
                  (m) =>
                    (m.data as FunctionCallMessageData)?.functionCall
                      ?.functionCall?.id === call.id,
                );
                const isLoading = functionCallMessage?.finishedAt === undefined;

                steps.push(
                  <Stepper.Step
                    key={functionCallMessage?.id}
                    label={functionCallTitles.get(call.name)}
                    description={
                      functionCallMessage?.data &&
                      MessageProcessor.GetComponent(
                        functionCallMessage.data,
                        isLoading,
                      )
                    }
                    completedIcon={
                      functionCallMessage?.error && (
                        <ExecutionPlanFailedIcon
                          error={functionCallMessage.error}
                        />
                      )
                    }
                    loading={isLoading}
                  />,
                );
              });

              return steps;
            })}
          </Stepper>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
