import { useEffect, useState } from "react";
import { useGetShortLatestVersions } from "./api/useGetShortLatestVersions";
import {
  Accordion,
  Box,
  Button,
  Group,
  Loader,
  Modal,
  NumberInput,
  Table,
  Text,
  Textarea,
} from "@mantine/core";
import { useGetLatestVersion } from "./api/useGetLatestVersion";
import { PromptType } from "./enums/PromptType";
import { useCreatePromptVersion } from "./api/useCreatePromptVersion";
import { useDisclosure } from "@mantine/hooks";
import { useGetAllPromptVersions } from "./api/useGetAllPromptVersions";
import { PromptVersion } from "./models/PromptVersion";
import PromptVersionView from "./components/PromptVersionView";
import Editor from "@monaco-editor/react";

export default function AdminPrompts() {
  const { latestVersion, getLatestVersion } = useGetLatestVersion();
  const { shortLatestVersions, getShortLatestVersions } =
    useGetShortLatestVersions();
  const { createdPromptVersion, createPromptVersion } =
    useCreatePromptVersion();
  const { promptVersions, getAllPromptVersions } = useGetAllPromptVersions();
  const [promptName, setPromptName] = useState<null | PromptType>(null);
  const [promptText, setPromptText] = useState<string>("");
  const [latestVersionText, setLatestVersionText] = useState<string>("");
  const [latestVersionDescription, setLatestVersionDescription] =
    useState<string>("");
  const [promptDescription, setPromptDescription] = useState<string | null>("");
  const [historyOpened, { open, close }] = useDisclosure(false);
  const [temperature, setTemperature] = useState<number>(0.65);
  const [latestVersionTemperature, setLatestVersionTemperature] =
    useState<number>(0.65);
  const [maxTokens, setMaxTokens] = useState<number>(4096);
  const [latestVersionMaxTokens, setLatestVersionMaxTokens] =
    useState<number>(4096);

  useEffect(() => {
    getShortLatestVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promptName !== null) {
      getLatestVersion(promptName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptName]);

  useEffect(() => {
    if (!latestVersion) return;
    setPromptText(latestVersion.text);
    setPromptDescription(latestVersion.description);
    setTemperature(latestVersion.temperature);
    setMaxTokens(latestVersion.maxTokens);

    setLatestVersionText(latestVersion.text);
    setLatestVersionDescription(latestVersion.description ?? "");
    setLatestVersionTemperature(latestVersion.temperature);
    setLatestVersionMaxTokens(latestVersion.maxTokens);
  }, [latestVersion]);

  useEffect(() => {
    if (!createdPromptVersion) return;

    setPromptText(createdPromptVersion.text);
    setPromptDescription(createdPromptVersion.description);
    setTemperature(createdPromptVersion.temperature);
    setMaxTokens(createdPromptVersion.maxTokens);

    setLatestVersionText(createdPromptVersion.text);
    setLatestVersionDescription(createdPromptVersion.description ?? "");
    setLatestVersionTemperature(createdPromptVersion.temperature);
    setLatestVersionMaxTokens(createdPromptVersion.maxTokens);
  }, [createdPromptVersion]);

  const handlePromptVersionClick = (promptName: PromptType) => {
    setPromptName(promptName);
    return undefined;
  };

  const handleSaveClick = () => {
    if (promptName === null) return;
    if (
      window.confirm(
        "Are you sure you want to create a new version of this prompt? This new version will be used immediately.",
      )
    ) {
      createPromptVersion(
        promptName,
        promptText,
        promptDescription,
        temperature,
        maxTokens,
      );
    }
  };

  const revertToVersion = (version: PromptVersion) => {
    if (
      window.confirm(
        "Are you sure you want to revert to this version? This version will be used immediately.",
      )
    ) {
      createPromptVersion(
        promptName!,
        version.text,
        version.description,
        version.temperature,
        version.maxTokens,
      );
      close();
    }
  };

  const handleOpenHistory = () => {
    getAllPromptVersions(promptName!);
    open();
  };

  const closeHistory = () => {
    close();
  };

  return (
    <Box p={"10px"}>
      {!shortLatestVersions && <Loader />}
      {shortLatestVersions && promptName === null && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Updated At</Table.Th>
              <Table.Th>Author Email</Table.Th>
              <Table.Th>Edit</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {shortLatestVersions.map((version) => (
              <Table.Tr key={version.name}>
                <Table.Td>
                  <Text>{version.name}</Text>
                </Table.Td>
                <Table.Td>{version.createdAt.toString()}</Table.Td>
                <Table.Td>{version.authorEmail}</Table.Td>
                <Table.Td>
                  <Button
                    onClick={() => handlePromptVersionClick(version.name)}
                  >
                    Edit
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
      {promptName !== null && (
        <>
          {!latestVersion && <Loader />}
          {latestVersion && (
            <>
              <Text fz={"var(--mantine-font-size-xl)"} pb={"10px"}>
                Prompt Name: {promptName}
              </Text>
              <Group>
                <Box>
                  <Text>Prompt Description</Text>
                  <Textarea
                    value={promptDescription || ""}
                    onChange={(event) =>
                      setPromptDescription(event.currentTarget.value)
                    }
                    size={"md"}
                    w={"400px"}
                    radius={"xs"}
                    pb={"10px"}
                    maxRows={5}
                    autosize
                  />
                </Box>
                <Box>
                  <Text>Temperature</Text>
                  <NumberInput
                    value={temperature}
                    onChange={(value) =>
                      setTemperature(parseFloat(value.toString()))
                    }
                    size={"md"}
                    w={"100px"}
                    radius={"xs"}
                    pb={"10px"}
                    allowNegative={false}
                    min={0}
                    max={1}
                    step={0.01}
                    allowDecimal
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Box>
                <Box>
                  <Text>Max Tokens</Text>
                  <NumberInput
                    value={maxTokens}
                    onChange={(value) =>
                      setMaxTokens(parseInt(value.toString()))
                    }
                    size={"md"}
                    w={"100px"}
                    radius={"xs"}
                    pb={"10px"}
                    allowNegative={false}
                    min={1}
                    max={4096}
                    step={1}
                  />
                </Box>
              </Group>
              <Text>Prompt Text</Text>
              <Editor
                value={promptText}
                onChange={(value: any) => setPromptText(value)}
                height="65vh"
                language={"liquid"}
              />
              <Group py={5} gap={5}>
                <Button
                  onClick={handleSaveClick}
                  disabled={
                    promptText === latestVersionText &&
                    promptDescription === latestVersionDescription &&
                    temperature === latestVersionTemperature &&
                    maxTokens === latestVersionMaxTokens
                  }
                >
                  Save
                </Button>
                <Button onClick={() => setPromptName(null)}>Go back</Button>
                <Button onClick={handleOpenHistory}>History</Button>
              </Group>
            </>
          )}
        </>
      )}
      <Modal
        title={
          <Text fw={700} fz={16}>
            Prompt Versions History
          </Text>
        }
        fullScreen
        opened={historyOpened}
        onClose={closeHistory}
      >
        <Accordion variant="separated">
          {promptVersions &&
            promptVersions
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime(),
              )
              .map((version, index) => (
                <PromptVersionView
                  version={version}
                  index={index}
                  key={index}
                  promptVersions={promptVersions}
                  revertToVersion={revertToVersion}
                />
              ))}
        </Accordion>
      </Modal>
    </Box>
  );
}
