import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatReport } from "../../../shared/models/ChatReport";

export const useCreateReport = (chatId: string) => {
  const { data: newReport, call } = useApiPrivateRequest<ChatReport>();
  const createReport = async () => {
    await call(`/chat/${chatId}/report`, "POST", undefined, {});
  };

  return { newReport, createReport };
};
