import { Box } from "@mantine/core";
import classes from "./styles/CitationHoverCard.module.css";
import { Citation, CitationIndex } from "./models/Citation";
import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../shared/enums/LocalStorageKeys";

interface CitationSidebarCardProps {
  index: number;
  citation: Citation;
}

export default function CitationSidebarCard({
  index,
  citation,
}: CitationSidebarCardProps) {
  const [, setSidebarCitation] = useLocalStorage({
    key: LocalStorageKeys.SidebarCitation,
  });

  const handleCitationClick = () => {
    setSidebarCitation(JSON.stringify(new CitationIndex(index, citation)));
  };

  return (
    <Box className={classes["box"]} onClick={handleCitationClick}>
      {index + 1}
    </Box>
  );
}
