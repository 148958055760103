import { useEffect } from "react";
import { dateTimeToString } from "../../../shared/utils/Dates";
import { Transcript } from "../models/Transcript";
import { Mark } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import classes from "../styles/TranscriptContent.module.css";

interface TranscriptContentProps {
  transcript: Transcript;
}

export default function TranscriptContent({
  transcript,
}: TranscriptContentProps) {
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 80,
    duration: 0,
  });
  useEffect(() => {
    if (!transcript.selectedChunkText) return;
    scrollIntoView();
  }, [transcript.selectedChunkText, scrollIntoView]);

  let targetComponentId = -1;
  let chunkStartIndex = -1;

  // find the component that contains the selected chunk
  if (transcript.selectedChunkText) {
    for (const component of transcript.components) {
      if (
        component.text &&
        component.text.includes(transcript.selectedChunkText)
      ) {
        targetComponentId = component.id;
        chunkStartIndex = component.text.indexOf(transcript.selectedChunkText);
        break;
      }
    }
  }

  return (
    <>
      <h2>{transcript.title}</h2>
      <p>{dateTimeToString(transcript.date)}</p>
      {transcript.components.map((component) => (
        <div key={component.id}>
          <b>{component.person}</b>
          {component.id === targetComponentId && (
            <div className={classes["div-with-margins"]}>
              {component.text && chunkStartIndex != -1 ? (
                <>
                  {component.text.substring(0, chunkStartIndex)}
                  <div className={classes["inline-div"]} ref={targetRef}>
                    <Mark>{transcript.selectedChunkText}</Mark>
                  </div>
                  {component.text.substring(
                    chunkStartIndex + transcript.selectedChunkText!.length,
                  )}
                </>
              ) : (
                component.text
              )}
            </div>
          )}
          {component.id !== targetComponentId && <p>{component.text}</p>}
        </div>
      ))}
    </>
  );
}
