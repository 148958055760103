import { useGetNews } from "../../../sources/api/useGetNews";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { ScrollArea, Text } from "@mantine/core";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import classes from "../../styles/SidebarCitations.module.css";
import SidebarNewsLink from "./SidebarNewsLink";

interface SidebarNewsCitationProps {
  citation: CitationIndex;
}

export function SidebarNewsCitation({ citation }: SidebarNewsCitationProps) {
  const { news, getNews, isLoading } = useGetNews();
  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getNews(memoizedCitation.documentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {news && (
        <>
          <SidebarNewsLink
            title={news.title}
            url={news.sourceUrl}
            source={news.source}
          />
          <ScrollArea.Autosize
            scrollbars="y"
            offsetScrollbars
            scrollbarSize={4}
          >
            <Text className={`${classes["chunk-text"]}`}>{news.summary}</Text>
          </ScrollArea.Autosize>
        </>
      )}
    </>
  );
}
