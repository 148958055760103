import { Group, Badge, Text } from "@mantine/core";
import { GetMarketDataMessageData } from "../../models/message-data/GetMarketDataMessageData";
import FunctionCall from "../FunctionCall";

interface GetMarketDataMessageProps {
  data: GetMarketDataMessageData;
}

export default function GetMarketDataMessage({
  data,
}: GetMarketDataMessageProps) {
  return (
    <>
      {data.data.map((data, index) => (
        <Group key={index}>
          <Text>{data.toViewString()}</Text>
          <Badge color="primary" variant="filled">
            {data.source}
          </Badge>
        </Group>
      ))}
      <FunctionCall functionCalls={[data.functionCall]} />
    </>
  );
}
