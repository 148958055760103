import { Flex, Notification, Tabs } from "@mantine/core";
import StepList from "./components/step-related/StepList";
import { useGetChat } from "./api/useGetChat";
import { useEffect, useState } from "react";
import { useChatUpdates } from "../../shared/hooks/useChatUpdates";
import { ChatUpdateType } from "../../shared/enums/ChatUpdateType";
import ChatFooter from "../chat-footer/ChatFooter";
import { useSendUserInput } from "./api/useSendUserInput";
import AnalysisHeader from "./components/AnalysisHeader";
import { ChatMode } from "../../shared/enums/ChatMode";
import { useHasAdminRole, useHasRole } from "../../shared/hooks/useHasRole";
import { ChatProcessingStep } from "./models/ProcessingStep";
import { Chat } from "../../shared/models/Chat";
import MessageList from "./components/MessageList";
import { ChatMessage } from "./models/ChatMessage";
import { MessageProcessor } from "./models/MessageDataProcessor";
import { useGetStep } from "./api/useGetStep";
import { StepProcessor } from "../../shared/utils/StepProcessor";
import { ModelOutputStep } from "./models/chat-steps/ModelOutputStep";
import { useSendUserInputV2 } from "./api/useSendUserInputV2";
import { useGetMessages } from "./api/useGetMessages";
import { UserRole } from "../../shared/enums/UserRole";
import CenterLoader from "../../shared/components/loader/CenterLoader";
import classes from "./styles/ExistingChat.module.css";
import { useLocalStorage } from "@mantine/hooks";
import { useExportChat } from "./api/useExportChat";
import SideDrawer from "./components/SideDrawer";
import { LocalStorageKeys } from "../../shared/enums/LocalStorageKeys";
import AdminViewSwitch from "./components/AdminViewSwitch";
import { useSuggestQuestions } from "./api/useSuggestQuestions";
import { SuggestedQuestions } from "./components/SuggestedQuestions";
import ReportPage from "./components/ReportPage";
import { ChatReport } from "../../shared/models/ChatReport";
import { useCreateReport } from "./api/useCreateReport";

interface ExistingChatProps {
  chatId: string;
  userId?: string;
}

export default function ExistingChat({ chatId, userId }: ExistingChatProps) {
  const chatDisabled = useHasRole(UserRole.ChatDisabled);
  const { chat, getChat } = useGetChat(chatId, userId);
  const [inProgress, setInProgress] = useState(chat?.inProgress || false);
  const [steps, setSteps] = useState<ChatProcessingStep[]>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { messages: updatedMessages, getMessages } = useGetMessages(chatId);
  const { step: updatedStep, getStep } = useGetStep(chatId);
  const [hasError, setHasError] = useState(false);
  const isAdmin = useHasAdminRole();
  const [download, , clearDownload] = useLocalStorage({
    key: LocalStorageKeys.DownloadChatOnFinish,
  });
  const { exportChat } = useExportChat();
  const { newSuggestedQuestions, suggestQuestions } =
    useSuggestQuestions(chatId);
  const [suggestedQuestions, setSuggestedQuestions] = useState<
    string[] | undefined
  >(undefined);
  const [activeTab, setActiveTab] = useState<string | null>("chat");
  const { newReport, createReport } = useCreateReport(chatId);
  const [report, setReport] = useState<ChatReport | undefined>(chat?.report);

  const setChatProps = function (chat?: Chat) {
    setInProgress(chat?.inProgress || false);
    setSteps(chat?.steps || []);
    setMessages(chat?.messages || []);
    setHasError(chat?.steps.some((step) => step.failed) || false);
    if (chat?.finishedAt && download === chatId) {
      clearDownload();
    }
  };

  useEffect(() => {
    // Clear all the states before fetching the chat
    setChatProps();
    setSuggestedQuestions(undefined);
    getChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (!chat) return;
    setChatProps(chat);

    setReport(chat.report);
    setActiveTab("chat");

    if (!chat.finishedAt) return;

    if (chat.suggestedQuestions) {
      setSuggestedQuestions(chat.suggestedQuestions);
    } else {
      suggestQuestions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  useEffect(() => {
    if (!updatedMessages) return;
    setMessages(updatedMessages);
  }, [updatedMessages]);

  useEffect(() => {
    if (!updatedStep) return;
    setSteps((prevSteps) =>
      StepProcessor.ProccessUpdatesStep(prevSteps, updatedStep),
    );
    setHasError(updatedStep.failed);
  }, [updatedStep]);

  const { sendUserInput } = useSendUserInput(chatId);
  const { sendUserInputV2 } = useSendUserInputV2(chatId);

  useEffect(() => {
    if (!chat) return;

    if (inProgress && suggestedQuestions) {
      setSuggestedQuestions(chat.suggestedQuestions);
    }

    if (!inProgress && !suggestedQuestions) {
      suggestQuestions();
    }

    if (!inProgress) {
      getChat();
    }

    if (!chat.finishedAt && !inProgress && download === chatId) {
      exportChat(chatId);
      clearDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  useEffect(() => {
    if (!inProgress && newSuggestedQuestions) {
      if (newSuggestedQuestions.chatId === chatId) {
        setSuggestedQuestions(newSuggestedQuestions.suggestedQuestions);
      }
    } else {
      setSuggestedQuestions(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSuggestedQuestions]);

  useEffect(() => {
    if (!newReport) return;
    setReport(newReport);
  }, [newReport]);

  useChatUpdates(chatId, async (type, data) => {
    switch (type) {
      case ChatUpdateType.ChatStarted:
        setInProgress(true);
        break;
      case ChatUpdateType.ChatCompleted:
        setInProgress(false);
        break;

      case ChatUpdateType.ChatStepUpdated:
        await getStep(data.id);
        break;
      case ChatUpdateType.StepContentUpdated: {
        const stepId = data.id;
        // Get step from steps by id
        const updatedStep = steps.find(
          (step) => step.id === stepId,
        ) as ModelOutputStep;
        if (!updatedStep) return;
        updatedStep.completionPercentage = 50;
        updatedStep.output = (updatedStep?.output || "") + data.content;
        setSteps((prevSteps) =>
          StepProcessor.ProccessUpdatesStep(prevSteps, updatedStep),
        );
        break;
      }

      case ChatUpdateType.MessageUpdated:
        await getMessages();
        break;
      case ChatUpdateType.MessageContentUpdated: {
        const messageId = data.id;
        const content = data.content;
        setMessages((prev) =>
          MessageProcessor.ProcessContentUpdate(prev, messageId, content),
        );
        break;
      }
    }
  });

  const processUserInputV2 = async (
    input: string,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: number[],
    files?: File[],
    useNews?: boolean,
  ) => {
    setSuggestedQuestions(undefined);
    await sendUserInputV2(
      input,
      companyIds,
      filingIds,
      transcriptIds,
      files,
      useNews,
    );
  };

  return (
    <Flex className={classes["root"]}>
      <Flex className={classes["main"]}>
        {!chat && <CenterLoader />}
        {chat && (
          <>
            <Tabs value={activeTab} onChange={setActiveTab}>
              <Tabs.List grow>
                <Tabs.Tab value="chat">Chat</Tabs.Tab>
                <Tabs.Tab value="report" disabled={inProgress}>
                  Report
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>
            {activeTab === "chat" ? (
              <>
                {chat.mode === ChatMode.Analysis && (
                  <AnalysisHeader
                    chat={chat}
                    steps={steps}
                    hasError={hasError}
                    inProgress={inProgress}
                  />
                )}
                {isAdmin && <AdminViewSwitch />}
                {download === chatId && (
                  <Notification
                    loading
                    withBorder
                    onClose={clearDownload}
                    title="Playbook will be downloaded once completed"
                  />
                )}
                {steps.length > 0 && <StepList steps={steps} chat={chat} />}
                {messages.length > 0 && (
                  <MessageList messages={messages} chat={chat} />
                )}
                {!userId && (
                  <ChatFooter
                    chatId={chat.id}
                    inProgress={inProgress}
                    disabled={chat.mode === ChatMode.Analysis && hasError}
                    showInputArea={!chatDisabled}
                    processUserInput={
                      messages.length > 0 ? processUserInputV2 : sendUserInput
                    }
                    children={
                      !inProgress && (
                        <SuggestedQuestions
                          suggestedQuestions={suggestedQuestions || []}
                          processInput={processUserInputV2}
                        />
                      )
                    }
                    useNewsDefault={chat.metadata.useNews}
                  />
                )}
              </>
            ) : (
              <ReportPage
                chatId={chatId}
                report={report}
                setReport={setReport}
                createReport={createReport}
                existingReport={chat.report}
                isOutdated={
                  report?.createdAt &&
                  chat?.finishedAt &&
                  typeof report?.createdAt === "object" &&
                  typeof chat?.finishedAt === "object"
                    ? report.createdAt.getTime() < chat.finishedAt.getTime()
                    : false
                }
              />
            )}
          </>
        )}
      </Flex>
      <SideDrawer chatId={chatId} />
    </Flex>
  );
}
