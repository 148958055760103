import { useEffect, useRef, useState } from "react";
import { Button, Text, Tooltip } from "@mantine/core";

interface GeneratedSuggestedQuestionButtonProps {
  index: number;
  question: string;
  processQuestionFunction: (question: string, isGenerated: boolean) => void;
}

export function GeneratedSuggestedQuestionButton({
  index,
  question,
  processQuestionFunction,
}: GeneratedSuggestedQuestionButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [clippedText, setClippedText] = useState(question);

  const calculateClippedText = () => {
    if (!buttonRef.current) return;

    const fontSize = parseInt(
      getComputedStyle(buttonRef.current).fontSize || "12px",
      10,
    );
    const buttonWidth = buttonRef.current.offsetWidth;

    // 5.5 is an approximate average width of a character with font size 12px
    // multiplied by 1.2 just to be safe
    const maxCharsApprox =
      Math.floor(buttonWidth / (5.5 * (fontSize / 12))) * 1.2;

    setClippedText(
      question.slice(0, maxCharsApprox) +
        (question.length > maxCharsApprox ? "..." : ""),
    );
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      calculateClippedText();
    });

    if (buttonRef.current) {
      resizeObserver.observe(buttonRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, buttonRef.current]);

  return (
    <Tooltip key={index} label={question} withArrow openDelay={300} multiline>
      <Button
        ref={buttonRef}
        onClick={() => processQuestionFunction(question, true)}
        style={{ flex: 1, minWidth: 0 }}
        variant="outline"
        radius="xs"
      >
        <Text truncate="end" size="sm">
          {clippedText}
        </Text>
      </Button>
    </Tooltip>
  );
}
