import { Group, Stack, Text } from "@mantine/core";
import classes from "../../styles/SidebarCitations.module.css";
import { IconFileAnalytics } from "@tabler/icons-react";
import { useMemo } from "react";
import { generateDocumentLink } from "../../../../shared/utils/Strings";
import { CompanyDocumentView } from "../../../../shared/models/CompanyDocument";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";

interface SidebarCompanyDocumentLinkProps {
  document: CompanyDocumentView;
  chunkId?: number;
  includeCompanyName?: boolean;
}

export default function SidebarCompanyDocumentLink({
  document,
  chunkId,
  includeCompanyName = true,
}: SidebarCompanyDocumentLinkProps) {
  const link = useMemo(() => {
    return generateDocumentLink(document.id, document.type, chunkId);
  }, [document, chunkId]);

  const title = includeCompanyName
    ? document.title
    : `${document.formType} ${document.period}`;

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Stack className={classes["link-group"]}>
        <Group>
          <IconFileAnalytics
            color="var(--mantine-color-primary-5)"
            size={isLargeScreen ? 20 : 16}
          />
          <Text className={classes["link-text"]}>{title}</Text>
        </Group>
      </Stack>
    </a>
  );
}
