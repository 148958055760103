export function numberToString(value: number, decimalsInFloat: number): string {
  if (
    typeof value !== "number" ||
    value === undefined ||
    value === null ||
    isNaN(value)
  ) {
    throw new Error("Value must be a number");
  }

  if (decimalsInFloat < 0) {
    throw new Error("Decimals must be a positive number");
  }

  if (Math.abs(value) < 1) {
    const roundedValue = value.toFixed(decimalsInFloat);
    return parseFloat(roundedValue).toString();
  } else if (Math.abs(value) < 1e3) {
    const roundedValue = value.toFixed(3);
    return parseFloat(roundedValue).toString();
  } else if (Math.abs(value) < 1e6) {
    return value.toFixed(2);
  } else if (Math.abs(value) < 1e9) {
    const inMillions = (value / 1e6).toFixed(2);
    return parseFloat(inMillions).toString() + "M";
  } else if (Math.abs(value) < 1e12) {
    const inBillions = (value / 1e9).toFixed(2);
    return parseFloat(inBillions).toString() + "B";
  } else {
    const inTrillions = (value / 1e12).toFixed(2);
    return parseFloat(inTrillions).toString() + "T";
  }
}

export function parseIntOrUndefined(
  value: string | null | undefined,
  radix?: number,
): number | undefined {
  const parsedValue = parseInt(value ?? "", radix);
  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function parseFloatOrUndefined(
  value: string | null | undefined,
): number | undefined {
  const parsedValue = parseFloat(value ?? "");
  return isNaN(parsedValue) ? undefined : parsedValue;
}
