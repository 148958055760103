import { ChatMessageData } from "../ChatMessageData";
import { FunctionCall } from "../FunctionCall";

export class ModelOutputMessageData extends ChatMessageData {
  public content: string;
  public functionCalls?: FunctionCall[];

  constructor(data: any) {
    super(data);
    this.content = data.content;
    if (data.functionCalls) {
      this.functionCalls = data.functionCalls.map(
        (fc: any) => new FunctionCall(fc),
      );
    }
  }
}
