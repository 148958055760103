import { useApiPrivateFileRequest } from "../../../shared/hooks/useApiFile";
import {
  EventName,
  EventParameter,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useExportChatReport = () => {
  const { isLoading: isExporting, call } = useApiPrivateFileRequest();
  const exportChatReport = async (chatId: string) => {
    sendEvent(EventName.ExportChatReportSent, {
      [EventParameter.ChatId]: chatId,
    });
    await call(`/chat/${chatId}/report/export`, "GET");
  };

  return { isExporting, exportChatReport };
};
