import { useGetConsensusEstimates } from "../../../../shared/api/useGetConsensusEstimates";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { ScrollArea, Box, Text, rem } from "@mantine/core";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import classes from "../../styles/SidebarCitations.module.css";
import CompanyLink from "../../../sources/components/CompanyLink";
import { EstimatesTable } from "../../../sources/components/EstimatesTable";

interface SidebarEstimateCitationProps {
  citation: CitationIndex;
}

export function SidebarEstimateCitation({
  citation,
}: SidebarEstimateCitationProps) {
  const { estimates, getEstimates, isLoading } = useGetConsensusEstimates();
  const memoizedCitation = useMemo(() => citation, [citation]);

  const { fiscalPeriod, year, period, quarter, companyId } = useMemo(() => {
    const fiscalPeriod = memoizedCitation.chunkId!;
    const year = parseInt(fiscalPeriod.slice(-4));
    const period = fiscalPeriod.slice(0, 2) === "FY" ? "Annual" : "Quarterly";
    const quarter =
      period === "Quarterly" ? parseInt(fiscalPeriod.slice(1, 2)) : undefined;
    const companyId = parseInt(memoizedCitation.documentId);
    return { fiscalPeriod, year, period, quarter, companyId };
  }, [memoizedCitation]);

  useEffect(() => {
    getEstimates(companyId, year, year, period);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {estimates && estimates.length === 0 && (
        <Text className={classes["citation-text"]}>
          No consensus estimates available for this period.
        </Text>
      )}
      {estimates && estimates.length > 0 && (
        <>
          <Text className={classes["source-text"]}>
            {`${estimates[0].companyName} consensus estimates for ${fiscalPeriod}`}
          </Text>
          <ScrollArea.Autosize
            scrollbars="y"
            offsetScrollbars
            scrollbarSize={4}
          >
            <EstimatesTable
              estimates={
                period === "Annual"
                  ? estimates
                  : estimates.filter((e) => e.fiscalQuarter === quarter)
              }
              isOnHoverCard={false}
            />
          </ScrollArea.Autosize>
          <Box py={rem(4)}>
            <CompanyLink
              companyId={companyId}
              companyName={estimates[0].companyName}
            />
          </Box>
        </>
      )}
    </>
  );
}
