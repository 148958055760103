import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Filing } from "../models/Filing";

export const useGetFiling = () => {
  const {
    data: filing,
    call,
    isLoading,
  } = useApiPrivateRequest<Filing>((response) => new Filing(response));

  const getFiling = async (id: string, chunkId: number | null = null) => {
    await call(`/marketData/filing/${id}`, "GET", { chunkId });
  };

  return { filing, getFiling, isLoading };
};
