import { Button, Center, Stack } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { ChatMessage } from "../models/ChatMessage";
import { useRetryChat } from "../api/useRetryChat";
import { useHasAdminRole } from "../../../shared/hooks/useHasRole";

interface FailedMessageProps {
  chatId: string;
  message: ChatMessage;
  isLast: boolean;
}

export default function FailedMessage({
  chatId,
  message,
  isLast,
}: FailedMessageProps) {
  const isAdmin = useHasAdminRole();
  const { retryChat } = useRetryChat(chatId);

  return (
    <Center>
      <Stack>
        {isLast &&
          (isAdmin
            ? message.error
            : "Sorry, an error occurred while processing the previous message.")}
        {isLast && (
          <Center>
            <Button onClick={retryChat} size="md" leftSection={<IconRefresh />}>
              Try again
            </Button>
          </Center>
        )}
      </Stack>
    </Center>
  );
}
