import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { SuggestedQuestions } from "../models/SuggestedQuestions";

export const useSuggestQuestions = (chatId: string) => {
  const { data: suggestedQuestions, call } =
    useApiPrivateRequest<SuggestedQuestions>(
      (response) => new SuggestedQuestions(response),
    );
  const suggestQuestions = async () => {
    await call(`/chat/${chatId}/suggestQuestions`, "POST", undefined, {});
  };

  return {
    newSuggestedQuestions: suggestedQuestions,
    suggestQuestions,
  };
};
