import { AskDocumentMessageData } from "../../models/message-data/AskDocumentMessageData";
import FunctionCall from "../FunctionCall";
import Markdown from "react-markdown";

interface AskDocumentMessageProps {
  data: AskDocumentMessageData;
}

export default function AskDocumentMessage({ data }: AskDocumentMessageProps) {
  return (
    <>
      {data.answer && <Markdown>{data.answer}</Markdown>}
      <FunctionCall functionCalls={[data.functionCall]} />
    </>
  );
}
